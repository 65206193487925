<template>
  <b-card>
    <h4 style="padding-bottom: 15px;">
      Merchant Resources
    </h4>
    <b-row>
      <b-col
        md="6"
        class="d-flex justify-content-center flex-column align-items-center vertical-line"
      >
        <div class="top-btn">
          <b-button
            :href="getMerchantPortalURL"
            target="_blank"
            class="custom-button"
            variant="primary"
            style="margin-bottom:1px;"
          >
            Merchant Portal URL
          </b-button>
        </div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.bottom.v-primary="'URL link can be copied by clicking the copy link icon!'"
          variant="outline-none"
          class="custom-button"
          style="margin-top: 10px;"
          @click="copyMerchantPortalURL"
        >
          <feather-icon icon="LinkIcon" />
          Copy URL
        </b-button>
      </b-col>
      <b-col
        md="6"
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <div class="top-btn">
          <b-button
            :href="getAPIDocumentationURL"
            target="_blank"
            class="custom-button api-doc"
            style="margin-bottom:1px;"
          >
            API Documentation
          </b-button>
        </div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.bottom.v-primary="'URL link can be copied by clicking the copy link icon!'"
          variant="gradient-none"
          class="custom-button"
          style="margin-top: 10px;"
          @click="copyAPIDocURL"
        >
          <feather-icon icon="LinkIcon" />
          Copy URL
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import {
  merchantPortalUrl, apiDocumentationUrl,
} from '@/constants/config'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [SuccessMessage, ErrorMessage],

  data() {
    return {
      merchantPortalUrl,
      apiDocumentationUrl,
    }
  },

  computed: {
    getMerchantPortalURL() {
      return merchantPortalUrl
    },
    getAPIDocumentationURL() {
      return apiDocumentationUrl
    },
  },

  methods: {

    // create common copyURL method to access both copyMerchantPortalURL and copyAPIDocURL methods
    copyURL(urlText) {
      try {
        const textarea = document.createElement('textarea')
        textarea.value = urlText
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
        this.showSuccessMessage('Link has been copied successfully!')
      } catch (error) {
        this.showErrorMessage('Failed to copy link')
      }
    },

    // copyMerchantPortalURL method
    copyMerchantPortalURL() {
      this.copyURL(this.merchantPortalUrl)
    },

    // copyapiDocumentationUrl method
    copyAPIDocURL() {
      this.copyURL(this.apiDocumentationUrl)
    },
  },
}

</script>

<style scoped>
.vertical-line {
  border-right: 3px solid #ccc;
  padding-right: 15px;
}

.custom-button {
  margin-top: 20px;
  margin-left:60px;

}

.api-doc {
    background-color: #7366f0 !important;
    border-color: #7366f0 !important;
}

.api-doc:enabled {
    background-color: #7366f0 !important;
    border-color: #7366f0 !important;
}
</style>
